<template>
  <TableTemplate
    :table-name="tableName"
    :table-columns="tableColumns"
    :use-language-selector="useLanguageSelector"
    :use-import="useImport"
  />
</template>

<script>
import TableTemplate from './TableTemplate';

export default {
  components: {
    TableTemplate
  },
  data() {
    return {
      tableName: 'language',
      tableColumns: [
        {
          id: 'id',
          name: 'ID',
          allowEdit: false
          // languageDependant: false
          // dataType: 'string'
        },
        {
          id: 'code',
          name: 'code'
        },
        {
          id: 'name',
          name: 'Name'
        }
      ],
      useLanguageSelector: false,
      useImport: false
    };
  }
};
</script>
